import React, { useState } from 'react';

import { Link, graphql } from 'gatsby';

import indexLogo from "../../images/icon/logo.jpg";

import Image from 'gatsby-image';

const Header = ({ route }) => {
    
    console.log(route);
    const [ _isToggle, _setToggle ] = useState(false);

    const setToggle = () => {
        _setToggle(prevToggle => !prevToggle);
    }

    return (
        <header className="header">
            <nav className="header__navigation">
                <ul className={ _isToggle === true ? "header__list active" : "header__list"}>
                    <li className="header__logo"><img className="header__logo-image" src={ indexLogo } /></li>
                    <li className={ 
                        route === "home" && _isToggle ? 
                        "header__item active current" :
                        _isToggle ? 
                        "header__item active" : 
                        route === "home" ? 
                        "header__item current" : 
                        "header__item" }>
                        <Link className="header__link" to="/">
                            <span className="u-english-text">Home</span>
                        </Link>
                    </li>
                    <li className={ 
                        route === "company" && _isToggle ? 
                        "header__item active current" : 
                        _isToggle ? 
                        "header__item active" : 
                        route === "company" ? 
                        "header__item current" :
                        "header__item" }>
                        <Link className="header__link" to="/company/">会社案内</Link>
                    </li>
                    <li className={ 
                        route === "system" && _isToggle ?
                        "header__item active current" :
                        _isToggle ? 
                        "header__item active" : 
                        route === "system" ? 
                        "header__item current" :
                        "header__item" }>
                        <Link className="header__link" to="/system/">業務・設備</Link>
                    </li>
                    <li className={ 
                        route === "recruit" && _isToggle ?
                        "header__item active current" :
                        _isToggle ? 
                        "header__item active" : 
                        route === "recruit" ? 
                        "header__item current" :
                        "header__item" }>
                        <Link className="header__link" to="/recruit/">採用情報</Link>
                    </li>
                    <li className={ 
                        route === "contact" && _isToggle ?
                        "header__item header__button header__button--primary active current" :
                        _isToggle ? 
                        "header__item header__button header__button--primary active" : 
                        route === "contact" ?
                        "header__item header__button header__button--primary current" : 
                        "header__item header__button header__button--primary" }>
                        <Link className="header__link" to="/contact/">お問い合わせ</Link>
                    </li>
                    <li className={ 
                        route == "members" && _isToggle ?
                        "header__item header__button header__button--primary active current" :
                        _isToggle ? 
                        "header__item header__button header__button--secondary active" : 
                        route === "members" ?
                        "header__item header__button header__button--secondary current" :
                        "header__item header__button header__button--secondary" }>
                        <Link className="header__link" to="/members/">会員専用</Link>
                    </li>
                    <button 
                        className={
                            _isToggle ?
                            "header__toggle-menu active" :
                            "header__toggle-menu"
                        }
                        onClick={ setToggle }
                        aria-expanded={ _isToggle }>
                        <span className="header__toggle-bars">&nbsp;</span>
                    </button>
                </ul>
            </nav>
        </header>
    )
}

export default Header;
