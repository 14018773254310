import React from 'react';
import { Helmet } from 'react-helmet';

import Header from './Header';
import Footer from './Footer';
import SEO from '../seo';

import "../../styles/main.css";

const layout = ({ children, location, metadata }) => {
  return (
    <>
    <Helmet>
      <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Noto+Serif+JP|Noto+Serif+SC" />  
    </Helmet>
    <SEO is404={ metadata.is404 || false } title={ metadata.title } />
      <Header route={ location } />
        { children }
      <Footer route={ location }/>
    </>
  )
}

export default layout;
