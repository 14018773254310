import React from 'react';

import { Link } from 'gatsby';

const footer = ({ route }) => {
    return (
        <footer className="footer">
            <div class="footer__container">
                <div class="footer__flex-container">
                    <nav className="footer__navigation footer__block">
                        <h3 class="footer__header heading-tertiary">Links</h3>
                        <ul className="footer__list">
                            <li className={
                                route === "home" ? "footer__item current" : "footer__item"
                            }>
                                <Link className="footer__link" to="/"><span class="u-english-text">Home</span></Link>
                            </li>
                            <li className={
                                route === "company" ? "footer__item current" : "footer__item"
                            }>
                                <Link className="footer__link" to="/company/">会社案内</Link>
                            </li>
                            <li className={
                                route === "system" ? "footer__item current" : "footer__item"
                            }>
                                <Link className="footer__link" to="/system/">業務・設備</Link>
                            </li>
                            <li className={
                                route === "recruit" ? "footer__item current" : "footer__item"
                            }>
                                <Link className="footer__link" to="/recruit/">採用情報</Link>
                            </li>
                            <li className={
                                route === "contact" ? "footer__item current" : "footer__item"
                            }>
                                <Link className="footer__link" to="/contact/">お問い合わせ</Link>
                            </li>
                            <li className={
                                route === "members" ? "footer__item current" : "footer__item"
                            }>
                                <Link className="footer__link" to="/members/">会員専用</Link>
                            </li>
                        </ul>
                    </nav>
                    <div className="footer__contact footer__block">
                        <h3 class="footer__header heading-tertiary">株式会社テストサービス</h3>
                        <ul className="footer__list">
                            <li className="footer__item">TEL：（03）3959-5521</li>
                            <li className="footer__item">FAX：（03）3959-6566</li>
                        </ul>   
                    </div>
                    <div className="footer__address footer__block">
                        <h3 class="footer__header heading-tertiary">住所</h3>
                        <p className="footer__address-text">〒173-0024 東京都板橋区大山金井町47-12</p>
                    </div>
                </div>
                <div className="footer__copyright">
                    <span className="u-english-text">
                        Copyright © { new Date().getFullYear() } Test Service Co., LTD. All Rights Reserved.
                    </span>
                </div>
            </div>
        </footer>
    )
}

export default footer;
