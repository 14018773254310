import React from 'react';
import PropTypes from "prop-types"
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ description, lang, meta, title, is404 }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            title
            description
            keywords
            ogTitle
            ogSiteName
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;

  const metaTitle = title || site.siteMetadata.title;

  return (
    !is404 ? <Helmet
      htmlAttributes={{
        lang,
      }}
      title={ metaTitle }
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: site.siteMetadata.ogTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: site.siteMetadata.siteUrl,
        },
        {
          property: `og:site_name`,
          content: site.siteMetadata.ogSiteName,
        },
        {
          name: 'robots',
          content: 'index',
        },
      ].concat(
        {
          name: `keywords`,
          content: site.siteMetadata.keywords.join(`, `),
        }
      )}
    /> : <Helmet htmlAttributes={{ lang }} title={ metaTitle } meta={ [{ name: 'robots', content: 'noindex, nofollow', }] } />
  )
}

SEO.defaultProps = {
  lang: `ja-jp`,
  meta: [],
  description: ``,
  is404: false,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  is404: PropTypes.bool,
}

export default SEO;
